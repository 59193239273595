<template>
  <ClientOnly>
    <Teleport to="body">
      <TransitionRoot appear :show="open" as="template">
        <Dialog class="tw-relative tw-z-50" @close="open = false">
          <TransitionChild
            as="template"
            enter="tw-duration-300 tw-ease-out"
            enter-from="tw-opacity-50 md:tw-opacity-0"
            enter-to="tw-opacity-100"
            leave="tw-duration-200 tw-ease-in"
            leave-from="tw-opacity-100"
            leave-to="tw-opacity-0"
          >
            <div class="tw-fixed tw-inset-0 tw-bg-gray-800/50" aria-hidden="true" />
          </TransitionChild>
          <div
            class="tw-fixed tw-inset-0 tw-h-screen tw-w-screen tw-overflow-y-auto md:tw-inset-y-0 md:tw-right-0"
          >
            <div class="tw-flex tw-min-h-full tw-items-center tw-justify-end tw-overflow-y-hidden">
              <TransitionChild
                as="template"
                enter="tw-transform-gpu tw-duration-200 tw-ease-out"
                enter-from="tw-translate-y-5 md:tw-translate-x-5 md:tw-translate-y-0 tw-opacity-0"
                enter-to="tw-translate-y-0 md:tw-translate-x-0 md:tw-translate-y-0 tw-opacity-100"
                leave="tw-transform-gpu tw-duration-150 tw-ease-in"
                leave-from="tw-translate-y-0 md:tw-translate-x-0 md:tw-translate-y-0 tw-opacity-100"
                leave-to="tw-translate-y-5 md:tw-translate-x-5 md:tw-translate-y-0 tw-opacity-0"
              >
                <DialogPanel class="tw-min-h-screen tw-w-full tw-bg-white md:tw-max-w-lg">
                  <slot />
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useVModel } from '@vueuse/core'

const props = withDefaults(defineProps<{ modelValue: boolean }>(), { modelValue: false })

const emit = defineEmits(['update:modelValue'])

const open = useVModel(props, 'modelValue', emit)
</script>
