<template>
  <Drawer v-model="open">
    <div class="tw-space-y-4 tw-px-6 tw-pb-48 tw-pt-4 md:tw-px-10 md:tw-py-6">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-text-3xl tw-font-bold tw-text-gray-800"><slot name="title" /></div>
        <button
          type="button"
          class="tw-rounded-full tw-p-2 tw-transition-colors hover:tw-bg-gray-100"
          @click="open = false"
        >
          <XMark class="tw-text-gray-800 tw-transition-colors hover:tw-text-gray-800/75" />
        </button>
      </div>
      <slot />
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'

import XMark from '~icons/heroicons-solid/x-mark'

const props = withDefaults(defineProps<{ modelValue: boolean }>(), { modelValue: false })

const emit = defineEmits(['update:modelValue'])

const open = useVModel(props, 'modelValue', emit)
</script>
